import { DiagramConnection, FlowStep, ModelConnection } from '@icepanel/platform-api-client'

export const type = (
  step: Pick<FlowStep, 'type' | 'originId' | 'targetId' | 'viaId'>,
  diagramConnections: Record<string, DiagramConnection>,
  modelConnections: Record<string, ModelConnection>
) => {
  if (!step.originId) {
    return { emptyReason: 'Select an object first' }
  }

  const options: NonNullable<FlowStep['type']>[] = []

  if (step.originId) {
    options.push('self-action')

    for (const connection of Object.values(diagramConnections)) {
      const model = connection.modelId ? modelConnections[connection.modelId] : undefined
      if (model?.direction === 'bidirectional' && (connection.originId === step.originId || connection.targetId === step.originId)) {
        options.push('outgoing', 'reply')
      } else if (model?.direction === 'outgoing' && connection.originId === step.originId && connection.targetId) {
        options.push('outgoing')
      } else if (model?.direction === 'outgoing' && connection.targetId === step.originId && connection.originId) {
        options.push('reply')
      }

      if (options.includes('outgoing') && options.includes('reply')) {
        break
      }
    }
  }

  if (options.length) {
    return options
  } else {
    return { emptyReason: 'There are no possible types from this object' }
  }
}

export const target = (
  step: Pick<FlowStep, 'type' | 'originId' | 'targetId' | 'viaId'>,
  diagramConnections: Record<string, DiagramConnection>,
  modelConnections: Record<string, ModelConnection>
) => {
  if (!step.originId) {
    return { emptyReason: 'Select an object first' }
  }
  if (!step.type) {
    return { emptyReason: 'Select a message type first' }
  }
  if (step.type === 'self-action') {
    return { emptyReason: 'Self action used' }
  }

  const options = [
    ...new Set(Object
      .values(diagramConnections)
      .map(o => ({
        connection: o,
        model: o.modelId ? modelConnections[o.modelId] : undefined
      }))
      .map(o => {
        if (o.model?.direction === 'bidirectional' && o.connection.originId === step.originId && o.connection.targetId) {
          return [o.connection.targetId]
        } else if (o.model?.direction === 'bidirectional' && o.connection.targetId === step.originId && o.connection.originId) {
          return [o.connection.originId]
        } else if (o.model?.direction === 'outgoing' && step.type === 'outgoing' && o.connection.originId === step.originId && o.connection.targetId) {
          return [o.connection.targetId]
        } else if (o.model?.direction === 'outgoing' && step.type === 'reply' && o.connection.targetId === step.originId && o.connection.originId) {
          return [o.connection.originId]
        } else {
          return []
        }
      })
      .flat())
  ]

  if (options.length) {
    return options
  } else if (step.type === 'reply') {
    return { emptyReason: 'There are no incoming connections from your selection' }
  } else {
    return { emptyReason: 'There are no outgoing connections from your selection' }
  }
}

export const connection = (
  step: Pick<FlowStep, 'type' | 'originId' | 'targetId' | 'viaId'>,
  diagramConnections: Record<string, DiagramConnection>,
  modelConnections: Record<string, ModelConnection>
) => {
  if (!step.originId) {
    return { emptyReason: 'Select an object first' }
  }
  if (!step.type) {
    return { emptyReason: 'Select a message type first' }
  }
  if (!step.originId) {
    return { emptyReason: 'Select an object first' }
  }
  if (step.type === 'self-action') {
    return { emptyReason: 'Self action used' }
  }

  const options = [
    ...new Set(Object
      .values(diagramConnections)
      .map(o => ({
        connection: o,
        model: o.modelId ? modelConnections[o.modelId] : undefined
      }))
      .filter(o => {
        if (o.model?.direction === 'bidirectional' && (
          (o.connection.originId === step.originId && o.connection.targetId === step.targetId) ||
          (o.connection.originId === step.targetId && o.connection.targetId === step.originId)
        )) {
          return true
        } else if (o.model?.direction === 'outgoing' && step.type === 'outgoing' && o.connection.originId === step.originId && o.connection.targetId === step.targetId) {
          return true
        } else if (o.model?.direction === 'outgoing' && step.type === 'reply' && o.connection.targetId === step.originId && o.connection.originId === step.targetId) {
          return true
        } else {
          return false
        }
      })
      .map(o => o.connection.id))
  ]

  if (options.length) {
    return options
  } else if (step.type === 'reply') {
    return { emptyReason: 'No recipient available' }
  } else {
    return { emptyReason: 'No connections available' }
  }
}
