import { FlowStepType } from '@icepanel/platform-api-client'

const flowStepTypes: { id: FlowStepType, icon: string, text: string }[] = [
  {
    icon: '$fas-long-arrow-alt-right',
    id: 'outgoing',
    text: 'Outgoing'
  },
  {
    icon: '$custom-flow-reply',
    id: 'reply',
    text: 'Reply'
  },
  {
    icon: '$fas-sync',
    id: 'self-action',
    text: 'Self action'
  }
]
export default flowStepTypes
